body {
  font-family: sans-serif;
  background:#000000;
  margin: 0;
}
.app {
  display: grid;
  align-content: center;
  justify-content: center;
  height:100vh;
}
.logo {
  height:100vh;
}